body.fixed {
	overflow: hidden;

	.section {
		z-index: 99999 !important; // TODO: Need to clean up z-indexes
	}
}

html {
	width: 100vw;
}

.product-list-modal {
	position: fixed;
	background: rgba($white, 0.7);
	z-index: 99999999 !important;
	pointer-events: none;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.4s ease-out;
	height: 0;
	width: 0;
	left: -99999999999px;

	&--active {
		opacity: 1;
		pointer-events: all;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&__inner {
		width: 90vw;
    max-width: 600px;
		height: auto;
		background: $white;
		border: 1px solid $black;
		pointer-events: all;
		display: flex;
		padding: 2rem;
		flex-direction: column;
		transition: transform 0.4s ease-out;
		transform: translateY(-30px);

		@at-root .product-list-modal--active & {
			transform: translateY(0);
		}
	}

	&__title {
		text-align: center;
    	margin-bottom: 90px;
	}

	&__options-title {
		padding-bottom: 30px;

		@include media-breakpoint-up(md) {
			padding-right: 30px;
			padding-bottom: 0;
		}
	}

	&__detail {
		display: flex;
		flex-direction: row;

		@include media-breakpoint-up(sm) {
			flex-direction: column-reverse;
			align-items: flex-end;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		pointer-events: all;

		.boxed-button {
			margin-left: 0;
		}
		&__button {
			margin-right: 1rem;
		}

		&__options {
			display: flex;
			align-items: center;
			flex-direction: column;

			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
		}
		&__select {
			min-width: 200px;
			.choices {
				max-height: 30px;
				min-width: 150px;
			}
		}

		&__product-container {
			display: flex;
			margin-top: 2rem;
			width: 100%;
			align-items: center;
			flex-direction: column;

			@include media-breakpoint-up(xxs) {
				flex-direction: row;
			}

		}

		&__image-container {
			display: flex;
			position: relative;
			margin-bottom: 2rem;

			@include media-breakpoint-up(xxs) {
				flex-direction: row;
				margin-bottom: 0;
			}
		}
		&__image {
			align-self: flex-start;
			max-width: 70px;
			margin-right: em(20);
			max-height: 150px;
		}
		&__badge {
			width: 25px;
			height: 25px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
			top: 23px;
			left: -6px;
			background-color: white;
			border-radius: 100%;
		}

		&__description-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			@include media-breakpoint-up(sm) {
				gap: 0;
				flex-direction: row;
			}
		}
		&__item-description {
			width: 100%;
			margin-right: 1rem;
			padding-top: 3px;
		}
		&__item-heading {
			@extend .body-text;
		
			line-height: 1;
			font-family: $font-primary;
			font-weight: 800;
			margin-top: 0;
			color: $black;
			margin-bottom: em(14);
			position: relative;
			width: 100%;
			font-size: large;
		}
		&__qty-label {
			margin-top: 7px;
		}
		&__info {
			@extend .body-text;
			font-family: $font-primary;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0.18em;
			font-size: small;
		}
		&__price {
		  @extend .body-text;
	  
		  font-family: $font-primary;
		  font-weight: 800;
		  margin-bottom: em(15);
		  color: $black;
			font-size: large;
			padding-left: 10px;
			margin-left: 10px;
		}
	}

	.close {
		top: 6px;
    right: 6px;
    left: auto;
		position: absolute;
		z-index:9999;
		
		svg {
			stroke: $black;
			width: 20px;
			height: 20px;
		}
	}
	
}