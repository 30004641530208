.cookies-notice {
	z-index: 999999999;
	position: absolute;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;
	left: 0;
	bottom: 0;
	position: fixed;
	color: $black;
	
	@include media-breakpoint-up(xs) {
		flex-direction: row;
	}

	* {
		position: relative;
		z-index: 1;
	}

	&__buttons {
		margin-left: 2rem;
		justify-content: center;
		display: flex;

		@include media-breakpoint-up(xs) {
			justify-content: flex-end;
			margin-left: 2rem;
			min-width: 190px;
		}
	}

	p {
		padding: 0;
		text-align: center;
		margin-bottom: 1rem;

		@extend .body-text-sm;

		@include media-breakpoint-up(xs) {
			text-align: left;
			margin-bottom: 0;
		}
	}

	&__link {
		text-decoration: underline;
	}

	&__title {
		@extend .heading-secondary-xl;

		padding: 0 2rem;
		padding-bottom: 1rem;
	}

}