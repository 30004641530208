.vintages-slider {

  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: 0 auto;
  width: 920px;
  max-width: 100%;

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &__prev,
  &__next {
    text-decoration: underline;
    color: $text-color-primary;
    margin-top: -12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__years {
    display: flex;
    flex-direction: row;
    flex: 1;

    &:before,
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border: 1px solid lighten($text-color-primary, 55);
      border-radius: 100%;
      margin-top: -5px;
    }
  }

  &__date {
    @extend .heading-primary-sm;

    font-size: em(25);
    color: $black;
    font-style: italic;
    top: -15px;
    position: absolute;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    transform: translateX(-50%);
    left: 50%;
    min-width: 200px;
  }

  &__year {
    flex: 1;
    height: 1px;
    background: lighten($text-color-primary, 55);
    position: relative;
    display: flex;
    justify-content: center;

    &::after,
    &::before {
      content: "";
      height: em(8);
      width: 1px;
      background: lighten($text-color-primary, 55);
      display: block;
      align-self: flex-start;
      justify-self: flex-end;
      display: flex;
      transform: translateY(-100%);
      right: 50%;
      position: absolute;
      top: 0;
    }

    &::before {
      right: 0;
      transform: translateY(0);
    }

    &:last-child::before {
      display: none;
    }
  }

  &__slide {
    width: 100%;
    height: 4px;
    display: block;
    transform: translateY(-50%);
    opacity: 0;
    //transition: opacity 0.4s ease;
    padding: 30px 0;
    position: relative;
    z-index: 10;

    &:before {
      content: "";
      background: $text-color-primary;
      position: absolute;
      height: 3px;
      width: 100%;
      margin-top: -3px;
    }

    &:hover,
    &--active {
      opacity: 1;
    }
  }

  &__vintage-heading {
    @extend .heading-secondary-sm;
    @extend .heading-secondary-sm--underlined;

    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .vintages-dropdown {
    margin-top: 0;
    margin-bottom: 3rem;

    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }
  }
}