.card-usp {
	position: relative;

	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	max-width: 12rem;

	&__icon {
	//	@include fluid-property-map(font-size, ( xs: 64px, xxxl: 120px ));
		font-size: 64px;
		@include media-breakpoint-up($single-column-breakpoint) {
			font-size: 120px;
		}

		& > span {
			width: 1em;
			height: 1em;
			border: 1px solid #c0c0c0;
			border-radius: 50%;

			display: flex;
			justify-content: center;
			align-items: center;

			& > img {
				flex-basis: 40%;
				max-width: 40%;
				opacity: 0.7;
				width: 0.3em;
			}
		}
		
	}

	&__description {
		@extend .body-text-sm;

		text-align: center;
    min-width: 65%;

		margin-top: 2rem;
	}
}