.choices {
	@extend .body-text;

	max-width: 0;
	margin: auto;
	min-width: 140px;
	margin-top: auto;
	width: 100%;
	display: flex;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	max-width: none;
	height: em(50);

	@at-root .product__button-item & {
		border: 0;
	}

	@include media-breakpoint-up(xs) {

		//height: em(52);
	}

	@include media-breakpoint-up(xl) {}

	&__list--dropdown {
		margin-top: 0 !important;
		left:0;
	}

	&__inner {
		background: none;
		border: none;
		font-size: 20px;
		min-height: 0;
		padding: 0;
		padding-bottom: 0;
		padding-left: em(10);
	}

	&__single {
		padding: 0;
	}

	&__item {
		@extend .heading-secondary-sm;

		line-height: 1;
	}

	&[data-type*='select-one']:after {
		right: 25px;
		margin-top: -2.5px;
	}

	&[data-type*="select-one"] .choices__inner {
		padding-bottom: 0;
	}

	.choices__list--dropdown .choices__item--selectable {
		@extend .heading-secondary-sm;

		background: $background-secondary;
		padding-top: 16px;
		padding-bottom: 13px;
		padding-left: 20px;
		padding-right: 0;

		&:last-child {
			margin-bottom: 0;
		}

		&.is-highlighted {
			background: $background-tertiary;
			color: $white;
		}
	}
}
