.tabs {
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		transform: translateY(-100%);
		z-index: 10000;

		&__tab {
			@extend .heading-secondary-sm;
			color: $white;
			border: solid 1px $background-secondary;

			&--active {
				color: $black;
				background: $background-secondary;
			}

			a {
				height: 56px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				@include media-breakpoint-up(md) {
					width: 240px;
				}
			}
		}
}

.secondary-navigation {
	z-index: 9999999 !important;
	position: absolute;
	transform: translateY(65px);
	z-index: 10000;
	width: 100vw;
	overflow: auto;
	display: block;
	left: 0;
	background: black;
	
	@include media-breakpoint-up(md) {
    background: transparent;
		transform: translateY(-100%);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&__tabs {
		width: 100%;
		display: flex;
		flex-direction: column;
		display: none;

		&.active {
			display: flex;
		}

		@include media-breakpoint-up(md) {
			justify-content: center;
			background: transparent;
			flex-direction: row;
			display: flex;
		}
	}

	&__tab {
		@extend .heading-secondary-sm;
		color: $white;
		text-align: center;
		border: solid 1px $background-secondary;

		@include media-breakpoint-down(sm) {
			order: 2;
			border: 0;
		}
		

		&--active {
			color: $text-color-accent;

			@include media-breakpoint-up(md) {
				justify-content: center;
				color: $black;
				background: $background-secondary;
			}
			@include media-breakpoint-down(md) {
				order: 1;
			}
		}

		a {
			width: 100%;
			height: 56px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			transition: background 0.3s ease;

			@include media-breakpoint-up(md) {
				min-width: 200px;
			}

			@include media-breakpoint-up(lg) {
				width: 240px;
			}

			&:hover {
				background: rgba($background-secondary, 0.15);
			}
		}
	}
}

.open-nav {
	background: black;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
	padding: 0 40px;
	border-bottom: 1px solid white;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 1.5px;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.hero__scroll-icon {
		margin-top: 0;
	}
}