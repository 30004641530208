.order-summary {

  &__items {

  }

  &__item {
    @extend .cart-item__product;

    flex-direction: column;
  }

  &__content {
    @extend .cart-item__content;
  }

  &__detail {
    //@extend .cart-item__detail;
  }

  &__options {
    @extend .cart-item__options;

    font-size: 13px;
    margin-top: -12px;
    flex-direction: column;
  }

  &__option {
    margin-bottom: 5px;
  }

  &__heading {
    @extend .cart-item__heading;
  }

  &__price {
    @extend .cart-item__price;
  }

  /**
  * Order Totals
  **/
  &__totals {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
  }

  &__totals-heading {
    @extend .heading-primary-md;

    text-transform: uppercase;
  }

  &__totals-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  &__totals-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5rem 0;

    & > div {
      display: flex;
      align-items: center;
    }

    &--small {
      margin-top: -16px;
      * {
        font-weight: 100;
        font-size: 12px;
      }
    }
  }

  &__totals-list-item-heading {
    @extend .cart-item__heading;

    margin: 0;
  }
  &__totals-list-item-price {
    @extend .cart-item__price;

    min-width: 80px;
    text-align: right;
    display: flex;
    flex-direction: column;
    max-width: 113px;
    margin-bottom: 0;
    align-items: flex-end !important;

    @include media-breakpoint-up($single-column-breakpoint) {
      max-width: 100%;
    }
  }

  &__coupon-text {
    font-weight: 600;
    background: #f9f2ec;;
    color: #d1a377;
    border: 1px solid #d1a377;
    padding: 11px 20px;
    margin-bottom: 33px;
    display: block;
  }

  &__coupon-code {
    font-weight: 900;
    letter-spacing: 1px;
  }
}
