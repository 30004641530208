.filter-section {
	@include section;
  @include section-metrics;

  position: relative;
  padding: 0;
  padding-top: 0 !important;
  //z-index: $z-filters !important;

  &--shop {
    padding: 0 !important;
  }
}

body .filter-section:not(.js-include-in-scroll-indicator) {
  z-index: 99999!important;
}