@mixin panel-toggle {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;

  &--active {
    max-height: 3000px;
    overflow: visible;
  }

  @include media-breakpoint-up(md) {
    height: auto;
    overflow: visible;
    max-height: none;
  }
}


.filters {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 7rem 4rem 0rem;
  left: 0;
  top: 0;
  background: $background-primary;
  z-index: 9999999;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  


  &__container {


    @at-root .filters-stuck & {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      background: $black;
      opacity: 1;
    }
  }

  &--active {
    transform: translateX(0);
  } 

  @include media-breakpoint-up(md) {
    min-height: 60px;
    margin-top: 1rem;
    position: static;
    height: auto;
    padding: 0;
    background: transparent;
    flex-direction: row;
    justify-content: center;
    transform: none;
    z-index: 9;
    margin-top: 0;
  }

  &__list-title {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.18em;
	  font-family: $font-secondary;
    font-style: italic;

    font-weight: 800;
    margin-right: auto;
    white-space: nowrap;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      font-weight: 600;
      border-bottom: 0;
      margin: 0;
      padding: 0;
      margin-right: 0 !important;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    background: $background-primary;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0;
    padding-left: 1.25em;
    z-index: 99;
    overflow: visible;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      position: static;
      background: transparent;
      height: auto;
    }

    &--centered {
      justify-content: center;
      width: 100%;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__list-item {
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    width: 100%;
    position: relative;
    z-index: 9999;
    white-space: nowrap;

    &--dropdown {

      @include media-breakpoint-up(md) {
        margin-right: em(15);
        padding: 1.7rem 0;
        position: static;
        width: auto;
        display: flex;

        &.collapsible--active {
          .filters__dropdown-panel {
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
          }
    
          .filters__list-item-text::after {
            transform: rotate(0);
          }
        }
      }

      @include media-breakpoint-up(lg) {
        margin-right: em(40);
      }

    }

    @at-root .filters__list--centered & {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__list-item-text {
    @extend .heading-secondary-sm;

    font-weight: 600;
    position: relative;
    margin-left: em(20);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: left;

    @at-root .collapsible--active > & {
      border-bottom: 1px solid $text-color-accent;

      &:after {
        transform: rotate(0);
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-right: em(20);


      &:before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 1.2rem;
        background: $text-color-primary;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.2s ease-out;
      }

      &--active, &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin: em(18) 0;
      border-bottom: 0;
      font-weight: 900 !important;
      font-size: 13px;
    }

    &::after {
      @include arrow();

      transform: rotate(-90deg);
      transform-origin: center 25%;
      left: - em(20);
      top: 2px;
      margin: 0;
      transition: transform 0.3s ease;

      @include media-breakpoint-up(md) {
        right: - em(20);
        left: auto;
        margin-left: 0;
      }
    }

    @at-root .filters__list--blog & {
      &::after {
        content: "|";
        transform: none;
        border: none;
        height: 100%;
        position: relative;
        top: 0;
      }

      @include media-breakpoint-up(md) {
        padding: 1.7rem 0;
      }
    }

    @at-root .filters__list--blog .filters__list-item:last-child &::after  {
      display: none;
    }
  }

  &__dropdown-panel {
    background: $background-primary;
    width: 100%;
    display: flex;
    position: static;
    left: 0;
    z-index: 100;
    flex-direction: column;
    

    @include media-breakpoint-up(md) {
      box-shadow: 0px 33px 16px -21px rgba(10, 10, 10, 0.08);
    }

    @include panel-toggle;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-50px);
      transition: transform 0.4s ease-out, opacity 0.3s ease;
      top: 5rem;
      padding: 5rem;
    }

    &-row {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    &-button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;

      &--mobile {
        margin-bottom: 3rem;
      }
    }
  }

  &__dropdown-panel-col {
    margin-right: 0;

    &--buttons {
      display: none;

      @include media-breakpoint-up(md) {
        justify-content: flex-start;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__dropdown-panel__close {
    margin: 2rem;
  }

  &__dropdown-title {
    @extend .heading-secondary-sm;
    @extend .heading-secondary-sm--underlined;
    @extend .filters__list-item-text;

    display: inline-block;
    position: relative;
    padding-bottom: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: em(40);
      margin-left: 0;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-left: em(20);
    }
  }

  &__dropdown-list {
    padding-left: 0;
    list-style: none;

    @include panel-toggle;

    &--open {
      max-height: none;
    }

    .form__checkbox {
      margin-bottom: em(20);
    }

    .form__input-wrap {
      padding-left: 0;
    }

    .form__checkout-label {
      @extend .heading-secondary-sm;

      font-weight: 400;
      color: $text-color-primary;
    }

    &--sort {
      max-height: 100%;
      overflow: visible;
      align-self: flex-start;
      overflow: visible;
    }
  }

  &__dropdown-clear-btn {
    @extend .boxed-button;

    min-width: 140px !important;
  }

  &__list-close {
    margin: 6rem 4rem;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      stroke: #939598;
      fill: #939598;
      height: 26px;
      width: 26px;
      position: relative;
      left: 0;
      top: 0;
      pointer-events: none;
    }

    &:hover svg {
      stroke: $black;
      fill: $black;
    }
  }

  &__show-filter-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
  }

  &__show-filter-buttons-button {
    @extend .heading-secondary-lg;

    color: lighten($text-color-primary, 20);

    &:hover {
      cursor: pointer;
      color: $black;
    }

    @at-root .filters-stuck & {
      color: $white;

      &:hover {
        color: lighten($text-color-primary, 20);
      }
    }
  }

  &__filter-list {
    position: relative;
    z-index: 0;
    width: 100%;

    .tag {
      margin-top: 1rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }

  .choices {
    height: 25px;

    &__item {
      padding: 1.3rem 1rem !important;
      font-weight: 600;
    }
  }
}

.search-header__col--sort {
  overflow: visible !important;

  @include media-breakpoint-down($single-column-breakpoint) {
    display: none;
  }
}

.sort {
  @extend .filters;

  &--active {
    transform: translateX(-100%);
  }
}

.search-header {
  transition: opacity 0.2s ease;
  min-height: 60px;

  @at-root .filter-section & {
    margin: 0;
  }

  .choices {
    background: transparent !important;
  }

  @include media-breakpoint-up(md) {
    .filters__list-title.hidden-mobile {
      display:none !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .filters__list-title.hidden-mobile {
      display:block !important;
    }
  }
}

@at-root .filters-stuck .search-header .filters {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  @include media-breakpoint-up(md) {
    background: $black;
    color: $white;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  opacity: 1;

  .filters__filter-list {
    display: none;
  }

  .filters__dropdown-panel {
    top: 60px;
    color: $black;
    backface-visibility: hidden;
  }
  .choices__item--choice {
    color: #4a4a4a;
  }
  .choices__item--choice.is-highlighted {
    color: $white;
  }
  .choices::after, .collapsible__trigger::after {
    border-color: #ddd transparent transparent transparent;
  }
  .choices.is-open::after {
    border-color: transparent transparent #ddd transparent;
  }
}
@at-root .filters-stuck .filter-section {
  z-index: 9999999 !important;
}

.filters-stuck.header--pinned .search-header {
  opacity: 0;
}

.filter-section { 
  margin-bottom: -3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: -$section-spacing-vertical-large;
  }
}

.js-vintages-years, .js-select-url {
  font-size: 12px;
}