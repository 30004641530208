.card-product {
	display: flex;
	padding-right: 0;
  padding-left: 0;
  width: 100%;
  flex: 1;
  flex-direction: column;

  @at-root .flickity-slider & {
    @include media-breakpoint-up(lg) {
      min-height: 400px;
    }
  }

	&__content {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 3rem;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  &__badge {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    right: 50px;
    top: 10%;
    background-color: white;
    border-radius: 100%;
    z-index: 1;

    @include media-breakpoint-up(xs) {
      left: auto;
      right: 90px;
      top: 90px;
    }
  }

  &__image-container {
    margin-bottom: 0;
    height: auto;
    max-height: 250px;
    transition: opacity 0.2s ease;
    display: flex;

    @at-root .card-product:hover & {
      opacity: 0.2;
    }

    @at-root .card-product--featured-home & {
      max-height: 300px;
    }
  }

	&__image {
    padding-right: 2rem;
    padding-left: 2rem;
    object-fit: contain;
    width: 100%;
    height: auto;
  }

	&__title {
		@extend .heading-primary-xs;

    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: $black;
	}

	&__sub-title {
		@extend .heading-secondary-sm;
		@extend .heading-secondary-sm--underlined;

    margin-bottom: 0;
    color: $black;
    @include media-breakpoint-down(xs) {
      padding-bottom: 1px;
      display: inline-block;
      line-height: 1.2em;
      font-size: 0.8em;
      margin-top: -2px;
    }
	}

	&__info {
		@extend .heading-secondary-sm;

		font-weight: 600;
    margin-bottom: 0;
    color: $black;
    display: block;

    line-height: 2;
    flex-grow: 1;

    @at-root .card-list-product--gift__item &, .carousel__item--large &, .product--gift &, .product--merchandise & {
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }

    @at-root .card-large & {
      margin-bottom: 12px;
    }
  }

  &__discount {
    margin-top: auto;
    text-transform: uppercase;
    font-style: normal;
    display: block;
    margin-bottom: 5px;
  }

  &__wishlist {
    position: absolute;
    right: auto;
    margin-left: 20px;
    display: inline-block;
    bottom: 0;
  }

  &__add-to-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;


    @at-root .card-product--featured-home & {
      flex-direction: column;
      margin-top: 2rem;
    }

    @include media-breakpoint-down(sm) {
			.card-product__cart{
        font-weight: 900;
        color: #c88242;
        padding: 0.5rem;
        border-right: 1px solid #b3b3b3;
        text-transform: uppercase;
        line-height: 1rem;
      }
      .product__button--favourite{
        padding: 0.3rem 0.5rem;
      }
		}

    .product-form__list-group--add-to-cart {
      @include media-breakpoint-up('md') {
        display: flex;
      }
    }

  }


  &__status-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 1rem;

    @include media-breakpoint-down(xs) {
      top: -0.7em;

      @at-root .featured-wines & {
        top: -2.7em;
      }
    }
  }

  &__price {
    @extend .heading-primary-sm;

    color: $black;
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 100;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    justify-content: center;
    line-height: 0.5;
    //flex: 1;

    @at-root .card-list-product--gift__item &, .carousel__item--large &, .product--gift &, .product--merchandise & {
      @include media-breakpoint-down(md) {

      }
    }

    @at-root .card-product--featured-home & {
      margin-bottom: 2rem;
    }

    @at-root .featured-wines--has-text & {
      font-size: em(52);
    }

    @at-root .card-list-product & {
      font-size: em(32);
    }

    @at-root .card-list-product--gift & {
      font-size: em(32);
    }

    @at-root .featured-wines & {
      font-size: em(32);
    }

    &--featured {
      margin-top: 1rem;
      position: relative;
    }

    @at-root .card-product__content-detail & {
      @extend .heading-primary-xs;
      margin-right: 10px;
      display: flex;
    }

    &--rrp {
      font-size: 10px;
      line-height: 0.7;
      letter-spacing: 0.8px;
      margin-left: 9px;
    }

    .product__price-rrp{
      @extend .heading-secondary-sm;
      flex-basis: 100%;
      margin-left: 0px;
    }

  }

  &__price-container {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 63%;
    }
  }

  &__price-currency {
    @extend .heading-secondary-sm;

    font-weight: 100;
    font-size: em(7);
    font-style: normal;
    position: static;
    line-height: 2;
  }
  &__price-from {
    @extend .heading-secondary-sm;

    font-weight: 100;
    font-size: em(6);
    font-style: normal;
    text-transform: initial;
    position: static;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-top: 14px;
  }

  &__content-slider {
    display: flex;
    flex-direction: column;
    width: 200%;
    height: calc(100% - 24px);
  }

  &__content-preview, &__content-detail {
    width: 100%;
    padding-right: 0;
    transition: transform 0.4s ease, opacity 0.1s ease;
    //transform: translateX(-100%);
    display: inline-block;

    @include media-breakpoint-up(md) {
      @at-root .card-product:hover & {
        //transform: translateX(0);
      }
    }
    &--inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;

      &-preview {
        transition: all 0.3s ease-out;
        @include media-breakpoint-up(md) {

          .card-product__wishlist {
            display: none;
          }

          @at-root .card-product:hover & {
            opacity: 0;
          }
        }
      }

      &-detail {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-15%);
        height: 70%;
        margin-top: auto;
        margin-bottom: auto;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-out;
        padding: 45px;

        .card-product__wishlist {
          @include media-breakpoint-up(md) {
            display: inline-block;
            position: absolute;
            right: auto;
            top: auto;
            bottom: auto;
            margin-left: 120px;
            margin-top: -2px;
          }
        }

        a {
          //margin: 40px;
        }

        @include media-breakpoint-up(md) {
          @at-root .card-product:hover & {
            opacity: 1;
            transform: translateY(-50%);
            pointer-events: all;
          }
        }
      }

      .product-form__buttons {
        margin-bottom: 0;

        .product-form__button--wishlist {
          display: none;
        }
      }
    }
  }

  &__content-preview {
    //opacity: 1;
    pointer-events: none;
    @include media-breakpoint-up(md) {
      @at-root .card-product:hover & {
        pointer-events: all;
      }
    }
    .card-product__price{
      flex-wrap: wrap;
      margin-top: auto;
    }
  }

  &__content-detail {
    //opacity: 0;
    //pointer-events: none;

    @include media-breakpoint-up(md) {
      pointer-events: all;

      @at-root .card-product:hover & {
        //opacity: 1;
      }
    }

    &--inner {
      @include media-breakpoint-down(xxs) {
        margin-left: -8px;

        @at-root .carousel__item--large & {
          margin-left: 0;
        }
      }
    }
  }

  &__description {
    font-family: $font-secondary;
    font-style: italic;
    font-weight: 100;
    text-transform: none;
    position: relative;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 1rem;
    flex-grow: 0;

    @include media-breakpoint-up(sm) {
      font-size: em(18);
    }

    &--mobile {
      margin-bottom: 1rem;
    }

    .quote {
      color: $brand-primary;
      font-size: em(36);
      line-height: 1;
      position: absolute;
      left: auto;
      margin-left: -22px;
      top: 0px;
      padding: 0 !important;
    }
  }

  &__learn-more {
    @extend .heading-secondary-md;

    font-size: 13px;
    font-weight: 600;
  }

  &__animation-slide-viewport {
    overflow: hidden;
    height: 24px;
    max-width: 200px;
  }

  &__animation-slide-track {
    display: flex;
    flex-direction: row;
    transform: translateX(0);
    transition: transform 0.4s ease;

    @at-root .card-product:hover & {
      transform: translateX(-100%);
    }
  }

  &__animation-slide-slide {
    min-width: 100%;
  }
}

.js-product-listing.card-list {
  padding: 1rem;
}
