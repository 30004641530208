$nav-height-small: 80px;

.primary-navigation {
  background: $white;
  height: $primary-navigation-height-sm;
  position: absolute;
  width: 100%;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 2.24px;
  display: flex;
  z-index: 99999999;
  left: 0;
  top: 0;
  transition: transform 0.4s;
  backface-visibility: hidden;
  transform:
  translateY(0)
  translate3d(0, 0, 0);
  will-change: transform;
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-breakpoint-up($navigation-breakpoint) {
    background: transparent;
    height: $primary-navigation-height-md;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @include media-breakpoint-up(xxl) {
    padding: 0 5rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;

    &--left {
      display: none;

      @include media-breakpoint-up($navigation-breakpoint) {
        display: flex;
      }
    }
  }

  &__item {
    padding: 0;
    list-style-type: none;
    display: none;

    @include media-breakpoint-up($navigation-breakpoint) {
      display: block;
    }

    &--icons {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;

      @include media-breakpoint-up($navigation-breakpoint) {
        margin-left: 0;
      }
    }
  }

  &__link {
    font-weight: 600;
    position: relative;
    padding-bottom: 8px;
    white-space: nowrap;
    backface-visibility: hidden;
    color: white;

    &:before {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $white;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.2s ease-out;
    }

    &--active, &:hover {
      &:before {
        transform: scaleX(1);
      }
    }

    @include on-event() {
      text-decoration: none;
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    list-style-type: none;
    align-items: center;
    margin-left: -12px;
    margin-right: -12px;
    padding: 0;
    height: $nav-height-small;

    li {
      position: relative;
      height: 100%;
    }
  }

  &__cart-items {
    position: absolute;
    top: 43%;
    left: 24px;
    height: 20px;
    z-index: 99999999;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.4;
    transform: scale(1);
    transform-origin: center;

    @include media-breakpoint-up($navigation-breakpoint) {
      left: 27px;
    }

    @at-root .page-banner-large .headroom--top & {

    }

    &--added-to-cart {
      animation-name: added-to-cart;
      animation-duration: 4s;
    }
  }

  &__icon {
    //@extend .svg-icon;
	// @include hover-on(light);
    font-size: 11px;
    max-width: em(16);
    margin: 0 em(12);
    transition: color 0.4s ease;
    height: 100%;

    @include media-breakpoint-up($single-column-breakpoint) {
      @include hover-on(dark);
      font-size: 12px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 14px;
    }

    &--user {
    
    }
  
    &--cart {
      max-width: em(17);
      margin-top: em(-1);
    }
    
    &--search {
      max-width: em(20);
      margin-top: em(-2);
    }
  
    &--menu {
      max-width: em(20);
    }
  }

  &__logo-container {
    @include media-breakpoint-up($navigation-breakpoint) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-left: 30px;
      margin-right: 30px;
      flex: 0.4;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
  }

  &__logo {
    display: none;
    align-items: center;
    //justify-content: center;
    flex-direction: column;
    min-width: 200px;

    img {
      max-width: 100%;
      max-height: 100%;
     // height: 100%;
      //margin: auto;
    }

    &--small {
      display: flex;
      //height: 100%;
      max-width: 180px;
      //position: fixed;

      @include media-breakpoint-up($navigation-breakpoint) {
        display: none;
      }

      img {
        margin-top: 29px;
      }
    }

    &--large {
      display: none;
      position: absolute;
      top: $site-border-width;
      left: auto;

      @include media-breakpoint-up(md) {
        height: $primary-navigation-height-lg;
      }

      @include media-breakpoint-up($navigation-breakpoint) {
        display: flex;
        width: 145px;
        height: $primary-navigation-height-xl;

        @at-root .headroom--not-top & {
          display: none;
        }

        @at-root .primary-navigation--small & {
          display: none;
        }
      }

      @include media-breakpoint-up(xl) {
        width: 194px;
        height: $primary-navigation-height-xxl;
      }
    }

    &--sticky {
      display: none;
      //position: fixed;
      top: 0;
      left: auto;
      height: 100%;
      max-width: 200px;

      img {
        height: 25px;
      }

      @at-root .headroom--not-top & {
        @include media-breakpoint-up($navigation-breakpoint) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }


      @at-root .primary-navigation--small & {
        @include media-breakpoint-up($navigation-breakpoint) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }

    svg {
      max-width: 100%;
    } 
  }


  &--small {
    padding-left: 30px;
    padding-right: 30px;

    &.headroom--pinned {
      position: fixed;
    }

    @include media-breakpoint-up($navigation-breakpoint) {
      background: $white;
      height: $nav-height-small;
      transform: translateY(-$nav-height-small*5);

     .primary-navigation__logo {
        height: 75px;
     }

     .primary-navigation__list--left {
      justify-content: flex-start;

        .primary-navigation__item  {
          margin-right: 3rem;

          @include media-breakpoint-up('large') {
            margin-right: 4rem;
          }
        }
      }

      .primary-navigation__list--right {
        justify-content: flex-end;

        .primary-navigation__item:not(.primary-navigation__item--icons) {
          margin-right: 3rem;

          @include media-breakpoint-up('large') {
            margin-right: 4rem;
          }
        }
      }

      .primary-navigation__link {
        font-weight: 500;
        font-size: em(14);
        padding-bottom: em(6);
        height: 1px;

        &::after {
          background: $text-color-primary;
        }

        &::before {
          background: $text-color-primary;
        }

        &--active, &:hover {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  /************************
  * Sticky Animation
  ************************/
  &.has-pinned {
    transform: translateY(-$nav-height-small*5);
    position: fixed;
    transition: transform 0.4s;
    backface-visibility: hidden;
  }

  &.headroom--pinned {
    transform:
      translateY(0)
      translate3d(0, 0, 0) !important;
  }

  &.headroom--pre-close {
    //animation-name: fade-out;
    //animation-duration: 0.4s;
    //animation-fill-mode: forwards;
  }

  &.headroom--not-top {
    @extend .primary-navigation--small;
  }
}

.page-banner-small .primary-navigation {
  @extend .primary-navigation--small;
}

.page-banner-small {
  @include media-breakpoint-up(sm) {
    // margin-top: 60px;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

//@include show_logo('primary');
@keyframes added-to-cart {
  from {
    transform: scale(1)
  }
  to {
    transform: scale(2)
  }
}


.primary-navigation {
  color: $grey;

  .primary-navigation__item {
    color: $text-dark;

    a {
      color: $text-dark;
    }
  }

  svg {
    color: $grey;
  }

  a:hover {
    svg {
      color: $text-dark;
    }
  }
}

@include media-breakpoint-up($navigation-breakpoint) {  
  .page-banner-large .primary-navigation {
    color: $text-dark;

    &__item--icons  {
      color: $grey;
    }

    /* Has pinned is when the menu is in the sticky state */
    &:not(.has-pinned) {
      color: $white;

      .primary-navigation__item {
        color: $white;

        a {
          color: $white;
        }

        &--icons  {
          color: $white;
        }
      }

      svg {
        color: $white;
      }
    }
  }
}

.page-banner-large .primary-navigation:not(.headroom--not-top) .primary-navigation__link,
.page-banner-large .primary-navigation:not(.headroom--not-top) svg {
  @include media-breakpoint-up($navigation-breakpoint) {  
    color: white !important;
  }
}