$circle-diameter: 3rem;

#sweetCalendar {
	position: relative;
	margin-bottom: 50px;

	& .container {
		// background-color: inherit;
		display: flex;
		flex-direction: column;

		& .header {
			position: relative;
			align-items: center;
			position: relative;
			align-items: center;
			display: flex;
			justify-content: space-around;
			margin-bottom: 1rem;

			& .month,
			& .left-arrow,
			& .right-arrow {
				display: flex;
				justify-content: center;
				padding: 1em 0;
				cursor: pointer;
			}

			& .left-arrow,
			& .right-arrow {
				&:after {
					content: '';
					display: block;
					width: 1em;
					height: 1em;
					border: 1px solid currentColor;
					border-left: none;
					border-top: none;
				}

				& > span {
					display: none;
				}
			}

			& .left-arrow:after {
				transform: rotate(135deg);
			}

			& .right-arrow:after {
				transform: rotate(-45deg);
			}

			& .month {
				@extend .heading-primary-sm;
				padding: 0;
				grid-column: 2 / 2;
			}
		}

		& .body {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-items: center;
			overflow: hidden;

			& .day-name {
				@extend .heading-secondary-sm;

				width: 14%; // fix for ie
				flex-basis: 14%;
				display: inline-flex; // fix for ie
				justify-content: center; // fix for ie
				height: 3rem;
				align-items: center;
				color: rgba(35, 35, 35, .7);
				font-weight: 700;
			}

			& .day-container {
				width: 14%; // fix for ie
				flex-basis: 14%;
				display: inline-flex; // fix for ie
				justify-content: center; // fix for ie
				align-items: center;
				position: relative;
			//	width: 100%;
				height: $circle-diameter * 2;
				background-color: $white;
				overflow: hidden;
				font-weight: 800;

				&:after {
					display: block;
					content: '';
					@include absolute-fill-parent;
					border-bottom: 1px solid $background-secondary;
					border-right: 1px solid $background-secondary;
					z-index: 0; // To ensure that multi-day events' top and bottom borders aren't hidden behind the light-grey day borders
				}

				&:not(:empty) {
					cursor: pointer;
				}

				&:empty {
					opacity: 0.5;
				}

				&:not(.selected):hover .day {
					z-index: 1000;
					// background-color: transparentize($brand-primary, 0.75);
					// transform: translate(-2px, -2px);
					// color: $white;
					// border: 1px solid transparentize($brand-primary, 0.5);
				}

				& .day {
					position: relative;
					// border-radius: 50%;
					// color: #232323;
					height: $circle-diameter;
					width: $circle-diameter;
					align-items: center;
					display: flex;
					align-items: center;
					justify-content: center;
					
					z-index: 100;
					pointer-events: none;

					&.selected {
						background-color: $brand-primary;
						border: none !important;
						color: $white !important;
					}

					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}

					&.today {
						border-radius: 50%;
						border: 1px solid transparentize($brand-secondary, 0.75);
						color: $brand-secondary
					}
					
					&.off-day {
						// background-color: $white;
						// font-weight: 700
					}
					
					& span {
						font-size: 14px;
						margin: 0;
						padding: 0;
						width: fit-content
					}
				}

				& .before {
					transform-origin: top right;
					transform: translateX($circle-diameter / 2) scaleX(2);
				}

				& .after {
					transform-origin: top left;
					transform: translateX(-$circle-diameter / 2) scaleX(2);
				}

				& .before, & .after {
					height: $circle-diameter;
					pointer-events: none;
					z-index: 1; // To ensure that multi-day events' top and bottom borders aren't hidden behind the light-grey day borders
				}
			}
		}
	}
}