@mixin card-text-with-image {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@include media-breakpoint-up($single-column-breakpoint) {
		flex-direction: row;
	}
}

@mixin card-text-with-image-with-icon {
	&__icon {
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		width: 10em;
		height: 10em;
		margin-bottom: 3em;
		max-width: 111px;
	}
}

@mixin card-text-with-image-with-image {
	// $single-column-breakpoint: lg;
	position: relative;

	&__details {
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;

		@at-root .page-heritage & {
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			justify-content: center;

			&::-webkit-scrollbar { display: none;  }
			-ms-overflow-style: -ms-autohiding-scrollbar;
		}


		::-webkit-scrollbar {
			display: none;
		}
	}

	&__image-mask {
		position: relative;
		min-width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;

		@include media-breakpoint-up($single-column-breakpoint) {
			min-width: 50%;
			width: 50%;
			max-width: 1 / 2 * 100%;
			flex-basis: 1 / 2 * 100%;
		}
	}

	&__image {
		@include absolute-fill-parent();
		background-size: cover;
		background-position: center;
	}

	// If no image aspect ratio is defined then square is the default.
	&__image {
		min-height: 100%;
	}

	// SQUARE IMAGE VARIANT

	&__image-mask {
		//flex-basis: 50%;
	}

	&__details {
		flex-basis: auto;

		@include media-breakpoint-up($single-column-breakpoint) {
			min-width: auto;
			width: 50%;
			flex-basis: 1 / 2 * 100%;
			max-width: 1 / 2 * 100%;
		}
	}

	&__image {
		// @include aspect-ratio(1, 1);

		// @include media-breakpoint-up($large-desktop-breakpoint) {
			@include aspect-ratio(4, 3);
		// }
	}

	// WIDE IMAGE VARIANT

	&--image-wide &__image {
		@include aspect-ratio(16, 9);
	}

	&--image-wide &__image-mask {
		@include media-breakpoint-up($single-column-breakpoint) {
			flex-basis: 7/12 * 100%;
			max-width: 7/12 * 100%;
		}

		@include media-breakpoint-up($large-desktop-breakpoint) {
			flex-basis: 60%;
			max-width: 60%;
		}
	}

	&--image-wide &__details {
		flex-basis: 1;

		@include media-breakpoint-up($single-column-breakpoint) {
			flex-basis: 5/12 * 100%;
			max-width: 5/12 * 100%;
		}

		@include media-breakpoint-up($large-desktop-breakpoint) {
			flex-basis: 40%;
			max-width: 40%;
		}
	}

	// NARROW IMAGE VARIANT

	&--image-narrow &__image {
		@include aspect-ratio(1, 1);
	}

	&--image-narrow &__image-mask {
		@include media-breakpoint-up($single-column-breakpoint) {
			min-width: 5/12 * 100%;
			flex-basis: 5/12 * 100%;
			max-width: 5/12 * 100%;
		}

		@include media-breakpoint-up($large-desktop-breakpoint) {
			min-width: 1/3 * 100%;
			flex-basis: 1/3 * 100%;
			max-width: 1/3 * 100%;
		}
	}

	&--image-narrow &__details {
		flex-basis: 1;

		@include media-breakpoint-up($single-column-breakpoint) {
			min-width: 7/12 * 100%;
			flex-basis: 7/12 * 100%;
			max-width: 7/12 * 100%;
		}

		@include media-breakpoint-up($large-desktop-breakpoint) {
			min-width: 2/3 * 100%;
			flex-basis: 2/3 * 100%;
			max-width: 2/3 * 100%;
		}
	}
}

@mixin card-text-with-image-with-centre-aligned-text {
	&--text-align-centre &__details {
		text-align: center;
		align-items: center;
	}
}

@mixin card-text-with-image-with-flipped-layout {
	// $single-column-breakpoint: lg;
	&--image-on-right {
		@include media-breakpoint-up($single-column-breakpoint) {
			flex-direction: row-reverse;
		}
	}
}

// @mixin card-text-with-image-with-buttons {
// 	&__buttons {
// 		display: flex;
// 		flex-wrap: wrap;

// 		margin-top: 3rem;
		
// 		flex-direction: column;
// 		text-align: center;
		
// 		@include media-breakpoint-up($single-column-breakpoint) {
// 			flex-direction: row;
// 		}

// 		& > *:not(:last-child) {
// 			margin-bottom: 1rem;

// 			@include media-breakpoint-up($single-column-breakpoint) {
// 				margin-bottom: 0;
// 				margin-right: 1rem;
// 			}
// 		}
// 	}
// }

@mixin card-text-with-image-with-details {
	&__details {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		text-align: left;
		align-items: flex-start;

		@include media-breakpoint-down($single-column-breakpoint) {
			padding: $card-padding-small;
		}

		@include media-breakpoint-up($single-column-breakpoint) {
			padding: $card-padding-large;
		}

		.section--bleed & {
			@include media-breakpoint-up($single-column-breakpoint) {
				padding: $card-padding-large-with-bleed;
			}
			padding-top: 10em;
			padding-top: 8em;
		}
	}

	&__super {
		@extend .heading-secondary-sm;
		@extend .heading-secondary-sm--underlined;
		margin-bottom: $padding-small;

		@at-root .text-with-image--text-align-centre & {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__heading {
		@extend .heading-primary-md;
		color: $black;
		max-width: 100%;

		@at-root .t-white-on-black & {
			color: $white;
		}
	}

	&__text {
		@extend .body-text;
		//@extend .body-text-max-width;
		margin-top: 3em;
		max-width: 30em;
	}

	&__extended {
		margin-top: $padding-small;
	}
}

@mixin card-text-with-image-with-newsletter-subscribe-button {
	&__subscribe-field {
		width: 100%;
		margin-top: 3em;
		.input-with-button__button{
			color: $text-color-primary-on-dark;
		}
		.input-with-button__text-field::placeholder{
			color: $text-color-primary;
		}
	}
}

@mixin card-text-with-image-with-fade-effect {
	&--fixed-text &__details {
		z-index: 100;
		transition-duration: 0.25s;
		transition-property: opacity;
		will-change: opacity;

		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 50%;
		opacity: 1;
	}
	
	.js-section.is-out &--fixed-text &__details {
		opacity: 0;
	}

	.js-section.is-out &--fixed-text &__super,
	.js-section.is-out &--fixed-text &__heading,
	.js-section.is-out &--fixed-text &__heading > em,
	.js-section.is-out &--fixed-text &__text,
	.js-section.is-out &--fixed-text &__actions, {
		opacity: 0;
	}

	.js-section.is-out &--fixed-text &__image {
		opacity: 0.25;
	}
	
	&--fixed-text &__image,
	&--fixed-text &__super,
	&--fixed-text &__heading,
	&--fixed-text &__heading > em,
	&--fixed-text &__text,
	&--fixed-text &__actions {
		transition-duration: 1s;
		transition-property: opacity;
		will-change: opacity;
	}

	&--fixed-text &__image {
		transition-delay: 0;
	}

	&--fixed-text &__super {
		transition-delay: 0;
	}

	&--fixed-text &__heading {
		transition-delay: 0.1s;
	}

	&--fixed-text &__heading > em {
		transition-delay: 0;
	}

	&--fixed-text &__text {
		transition-delay: 0.3s;
	}

	&--fixed-text &__actions {
		transition-delay: 0.4s;
	}
}

.card-text-with-image {
	@include card-text-with-image;
	@include card-text-with-image-with-details;
	@include card-text-with-image-with-image;
	@include card-with-buttons($justify-to-bottom: false);
	@include card-text-with-image-with-centre-aligned-text;
	@include card-text-with-image-with-flipped-layout;
	@include card-text-with-image-with-newsletter-subscribe-button;

	@include card-text-with-image-with-icon;

	// @include has-colour-schemes;

	@include media-breakpoint-up($single-column-breakpoint) {
		@include card-text-with-image-with-fade-effect;
	}
	
	@include media-breakpoint-up($single-column-breakpoint) {
		&--full-height {
			height: 100vh;
		}
	}
}