.card-article {
	@include card;
	@include card-with-image;
	@include card-with-image-at-top;
	@include card-with-image-height-locked-to-aspect-ratio;
	@include card-with-image-that-scales-on-hover;
	@include card-with-padding;
	@include card-with-white-background;

	&__date {
		@extend .heading-secondary-sm;
		
		& > span:first-child {
			font-weight: bold;
			&:after {
				content: ' |';
				letter-spacing: 1em;
			}
		}
	}

	&__title {
		@extend .heading-primary-sm;
		// border: 1px solid #f00;
		margin-block-start: 2rem;
		max-width: 100%;
	}
	
	&__description {
		@extend .body-text;
		margin-block-start: 1.5em;

		max-width: 100%;
	}
	
	&__cta {
		@extend .heading-secondary-sm;
		color: $brand-primary;
		// display: inline-block;
		margin-block-start: 3em;
		justify-self: flex-end;
		flex-grow: 1;
		font-weight: bold;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}