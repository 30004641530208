.redirect {
  .modal__background { // the background IS the close button
    background: $black;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    padding: .5rem;
    z-index: 0;
  }
  &__row {
    &.modal__slide {
      transform: translate(-50%, -50%);
      max-width: 500px;
      padding: 1rem;
      left: 50%;
      top: 50%;
      position: absolute;
      min-height: auto;
    }
    & .modal {
      &__close-icon {
        display: none;
        @include media-breakpoint-up(sm) {
          display: block;
        }
      }
      &__col {
        background-color: white;
        padding: .5rem;
      }
      &__border {
        border: 1px solid;
        padding: 2rem;
        @include media-breakpoint-up(sm) {
          padding: 2.5rem;
        }
      }
    }
  }
  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
  }

  &__button {
    @extend .boxed-button;
    @extend .text-button;
    min-width: 110px;
    margin: 0;
    @include media-breakpoint-up(sm) {
      min-width: 140px;
    }
    &--submit {
      border: 1px solid;
    }
  }
}