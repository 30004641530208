.page-intro {
  padding-bottom: $padding-large;
  padding-top: $padding-large;
  background: $background-secondary;

  &__heading {
    @extend .heading-primary-lg;

    line-height: 1;
    margin-bottom: 32px;
    color: black;
  }

  &__content {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__text {
    @extend .body-text;

    margin-bottom: 50px;
  }
}
