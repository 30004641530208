.regions {
  @include section;

  flex-direction: column;
  flex-wrap: nowrap;
  min-height: auto;

  &__gutter {
    @extend .section__gutter;
  }

  &__section-heading {
    // @extend .section__heading;
  }

  &__wrap {
    @extend .section__wrap;

    align-items: stretch;
    flex-direction: column;
  }

  &__row {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__col {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 2px;

    @include on-event() {
      text-decoration: none;

      .regions__image {
        transform: scale(1.1);
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      flex-basis: calc(33% - 2px);
      max-width: calc(33% - 2px);
      width: calc(33% - 2px);
    }
  }

  &__image {
    @include bg-image-overlay();

    position: relative;
    height: 0;
    padding-bottom: 30%;
    transition: transform 0.4s ease;
    backface-visibility: none;

    &:before {
      @include overlay( rgba($black, 0.2) );
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 100%;
    }
  }

  &__content {
    color: $white;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__heading {
    @extend .heading-primary-md;

    position: relative;
  }

  &__footer {
    // @extend .section__actions;
	
    justify-content: center;
  }

  &__button {
    @extend .boxed-button;
  } 
}
