.membership-benefits {

	.card-text-with-image {
		&__image-mask {
			min-width: unset;

			@include media-breakpoint-up($single-column-breakpoint) {
				flex-basis: 43%;
				max-width: 43%;
			}
		}

		&__details {
			@include media-breakpoint-up($single-column-breakpoint) {
				flex-basis: 57%;
				max-width: 57%;
			}

			.section {
				padding: 0;
				background: transparent;
			}
		}

		&__text {
			max-width: 500px;
		}
	}
	

	.card-list-usp {
		flex-wrap: wrap;
		flex-direction: row;
		margin-left: -1rem;
		margin-right: -1rem;
		margin-top: 3rem;

		&__item {
			flex-direction: row;
			min-width: 100%;
			flex-basis: 100%;

			@include media-breakpoint-up($single-column-breakpoint) {
				min-width: 50%;
				flex-basis: 50%;
			}
		}

		.card-usp {
			max-width: unset;
			flex-direction: row;
			margin: 1rem;

			&__description {
				text-align: left;
				margin-top: 0;

				margin-left: 1rem;

				@include media-breakpoint-up('lg') {
					margin-left: 2rem;
				}

				p {
					line-height: 1.5;
				}
			}

			&__icon > span {

				max-width: 60px;
				max-height: 60px;

				@include media-breakpoint-up('lg') {
					max-width: 80px;
					max-height: 80px;
				}
			}
		}
	}
}

.membership-benefits .card-usp {
	min-width: 100%;
}