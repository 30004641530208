.gift-items {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__product {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
    border-bottom: solid 1px $brand-tertiary;

    @at-root .cart-item--modal {
      padding: 2rem 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__product-detail-wrap {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  &__product-options {
    padding-top: 1.2rem;
    margin-left: auto;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  &__product-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
  }

  &__product-heading {
    @extend .heading-primary-sm;

    margin-top: 0;
  }

  &__product-sub-text {
    @extend .body-text;

    margin-top: 0;
    margin-bottom: 0;
  }

  &__product-price {
    @extend .body-text;

    margin-top: auto;
    font-weight: 900;
    color: $black;
    padding-top: 1.5rem;
  }

  &__image {
    margin-bottom: 3rem;
    max-width: 211px;
    align-self: flex-start;

    @include media-breakpoint-up(sm) {
      margin-right: 3rem;
      min-width: 200px;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }

  .form__checkmark {
    right: 0;
    left: auto;
  }

  .checkout__input-wrap {
    padding-right: 0;
  }
}