.card-library {
	@include card;
	@include card-with-image;
	// @include card-with-padding;
	@include card-with-image-height-locked-to-aspect-ratio;

	flex-direction: row;

	&__image-container {
		@include media-breakpoint-up(md) {
			min-width: 150px;
			margin-right: 20px;
		}
	}

	&__title {
		@extend .heading-primary-xxs;

		margin-bottom: 2rem;
		// border: 1px solid #f00;
		// margin-block-start: 2rem;
	}

	&__description {
		@extend .body-text;
		margin-block-start: 1.5em;
	}

	// @include item-hover-image-zoom;

	// flex-direction: row;

	// &__image-mask {
	// 	width: 20rem;
	// 	overflow: hidden;
	// }

	// &__content {
	// 	margin-left: $flexboxgrid-gutter-width / 2;
	// }

	// &__title {
	// 	@extend .heading-primary-xs;

	// 	margin-block-start: 1em;
	// 	margin-block-end: 0em;
	// 	margin-top: 0;
	// }

	// &__text {
	// 	@extend .body-text;
	// }
}

.js-product-listing.card-list--search {
	padding: 4rem;

	.list-heading {
		margin-bottom: 2rem;
		margin-top: 3rem;

		&:first-child {
			margin-top: 0;
		}
	}

	.js-card-list {
		margin-left: -2rem;
		margin-right: -2rem;
	}

	.seperator{
		margin: 8rem 0rem 3rem 0rem;
	}
}