.header {
  position: absolute;
  width: 100%;
  
  @include media-breakpoint-up($single-column-breakpoint) {
    display: none;
  }
}


