.select {
	margin-top: 1rem;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: .6em 1.4em .5em .8em;
	letter-spacing: 1.5px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #ccc;
	box-shadow: none;
	border-radius: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%, #fff 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
	filter: grayscale(1);

	&::-ms-expand {
		display: none;
	}

	&:hover {
		border-color: #888;
	}

	&::backdrop {
		border-color: #aaa;
		box-shadow: 0 0 1px 3px $text-color-primary;
		box-shadow: 0 0 0 3px -moz-mac-focusring;
		color: $text-color-primary;
		outline: none;
	}

	& option {
		font-weight: normal;
	}

	&::after {
		content: "";
		height: 0;
		width: 0;
		border-style: solid;
		border-color: #333333 transparent transparent transparent;
		border-width: 5px;
		position: absolute;
		right: 11.5px;
		top: 50%;
		margin-top: -2.5px;
		pointer-events: none;
	}

	&.error {
		border-color: #d1a377;
	}

	&.no-bg {
		background-color: transparent;
		background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
			linear-gradient(to bottom, transparent 0%, transparent 100%);

			border: none;
			width: auto;
			padding: 1.5rem;

			@extend .heading-secondary-sm;
	}
}