.input-with-button {
	position: relative;
	width: 100%;
	max-width: 32em;

	&__text-field {
		@extend .body-text;

		background-color: none;

		color: $text-color-primary;
		border: 1px solid darken($white, 20%);
		width: 100%;
		max-width: 100%;
		padding: 1em;

		.t-white-on-black & {
			color: $white;
			border-color: lighten($black, 20%);
		}

		&::placeholder {
			color: darken($white, 20%);
		}

		@at-root .input-with-button--light & {
			background: $white;
			color: $text-color-primary;
		}

		@at-root .input-with-button--dark & {
			background: $brand-secondary;
			color: $text-color-primary;
			border: 1px solid darken($white, 50%);
		}

		&:focus {
			outline: none;
		}

		&.error {
			border-color: rgb(209, 163, 119);
		}
	}

	&__input {
		background: none;

		&.has-coupon {
			opacity: 0;
		}
	}

	&__button {
		@extend .heading-secondary-sm;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		//   color: $black;
		//   font-weight: 500;
		//   text-transform: uppercase;
		background: none;
		border: none;
		padding: 2em;
		cursor: pointer;
		transition: color 0.4s ease;
		//   letter-spacing: 0.125em;
		display: flex;
		align-content: center;
		align-items: center;
		margin-right: 1rem;
		// font-size: 11px;

		@at-root .input-with-button--dark & {
			// color: $white;
		}

		&:focus {
			outline: none;
		}

		&:hover {
			color: darken($white, 50%);

			@at-root .input-with-button--dark & {
				color: darken($white, 20%);
			}
		}
	}

	.text-left {
		flex-direction: row;
		display: flex;
	}

	.choices__list--single {
		top: -2px;
	}

	.form__input-error-msg {
		position: absolute;
    width: 100%;
	}
}