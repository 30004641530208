@mixin heading-with-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;

	& > :not(:first-child) {
		margin-top: $padding-small;
	}

	@include media-breakpoint-up($single-column-breakpoint) {
		& > :not(:first-child) {
			margin-top: $padding-large;
		}
	}

	&__copy {
		@extend .body-text;
		@extend .body-text-max-width;

		text-align: center;
		padding-left: $padding-small;
		padding-right: $padding-small;

		& > :not(:last-child) {
			margin-bottom: 1.5em;
		}
	}

	&__super {
		@extend .heading-secondary-sm;
		@extend .heading-secondary-sm--underlined;

		display: inline-block;
	}

	&__heading {
		@extend .heading-primary-lg;

		color: $black;
	}
}

.heading-with-text {
	@include heading-with-text;
}