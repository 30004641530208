.event-single {
	display: flex;

	&--constrain {
		max-width: 1000px;
		margin: 0 auto;
	}

	&__item {
		@include wysiwyg;
	}

	&__label {
		@extend .body-text;

		color: $black;
		font-weight: 800;
		display: block;
	}

	&__select {
		width: 100%;
	}

	&__list {
		@extend .body-text;

		padding-left: 0;
		list-style: none;

		strong {
			font-weight: 800;
			color: $black;
		}

		li {
			margin-bottom: 1rem;
			padding-left: 0;
		}

		a {
			text-decoration: underline;
		}
	}
	

	.event-single__detail.col__large {
		min-width: 100%;

		@include media-breakpoint-up(md) {
			min-width: auto;
		}
	}

	&__book {
		min-width: 400px;
		max-width: 530px;
	}

	&__book-heading {
		@extend .heading-primary-sm;

		margin-bottom: 2.5rem;
		line-height: 1;
		margin-top: 3rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}

	&__qty-select {
		display: flex;
		flex-direction: row;
		margin-bottom: 3rem;
		margin-top: 2rem;
	}

	&__qty-select-input {
		@extend .select;

		max-width: 120px;
	}

	&__select-price {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 2rem;
	}

	&__form-heading {
		@extend .body-text;

		color: $black;
		font-weight: 800;
		display: block;
		margin-bottom: 1rem;
	}

	&__actions {
		margin-top: 4rem;
		margin-bottom: 4rem;
    justify-content: center;
    align-items: center;
		display: flex;
		flex-direction: column;

		p {
			margin-bottom: 1rem;
		}
	}

	&__add-to-cart {
		margin-top: 2rem;
	}

	&__col-detail {
		border-left: solid 1px darken($white, 20);
		margin-top: 2rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}

	&__content-image {
		background-position: center;
		background-size: cover;
		padding-bottom: 40%;
		margin-bottom: 3rem;
	}
	
	&__title {
		@extend .heading-primary-md;

		max-width: 800px;
		margin-bottom: 3rem;
	}

	&__title-super {
		@extend .heading-secondary-sm--underlined;

		align-self: flex-start;
		margin-bottom: 1rem;
	}

	&__external-book {
		margin-top: 2rem;
	}

	#sweetCalendar .container .body .day-container {
		height: 4rem;
		grid-template-columns: -webkit-max-content;
	}

	&__qty-add-btn {
		border-right: 0;
	}

	&__book-external {
		margin: 2rem 0 4rem 0;
	}

	&__detail .row {
		flex-direction: column;

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
}