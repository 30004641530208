.card-collage-bright {
	@include card;
	@include card-with-image;
	@include card-with-buttons($justify-to-bottom: true, $invert-on-hover: true);
	@include card-with-white-background;

	@include card-collage-with-text;
	@include card-collage-metrics;
	@include card-collage-with-image;
	@include card-with-image-that-dims-on-hover;

	@include media-breakpoint-up($single-column-breakpoint) {
		// @include card-slide-text-up-on-hover;
	}

	@include card-collage-show-image-on-hover;
	@include card-with-image-as-background;
	@include card-with-image-height-locked-to-aspect-ratio(1,1);

	&__image-mask {
		min-height: 300px;
	}

	&__super {
		left: 50%;
    transform: translateX(-50%);
	}

	&__image {
		@include media-breakpoint-down($single-column-breakpoint) {
			transform: none;
		}
	}

	&__title {
		max-width: 100%;
	}
}