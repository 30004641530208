.featured-membership-option {
	// @extend .section;

	display: flex;
	flex-direction: row;
	align-items: center;

	background-color: $background-tertiary;
	color: white;

	&__image-mask {
		position: relative;
		overflow: hidden;

		align-self: stretch;

		@include media-breakpoint-down(sm) {
			@include aspect-ratio(4,3);
		}

		@include media-breakpoint-up(sm) {
			flex-basis: 1/2 * 100%;
			max-width: 1/2 * 100%;
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		// @include aspect-ratio(4, 3);
		background-position: center;
		background-size: cover;
		transition-duration: 0.4s;
		transition-timing-function: ease-out;
		transition-property: transform, opacity;
	}

	&__details {
		padding: $padding-large;
		flex-basis: 1/2 * 100%;
		max-width: 1/2 * 100%;
		padding-right: 15%;
	}

	&__heading {
		@extend .heading-primary-md;
	}

	&__text {
		@extend .body-text;
	}

	//   flex-wrap: nowrap;
	//   min-height: 0;
	//   flex-direction: column;

	//   &__gutter {
	//     @extend .section__gutter;
	//   }

	//   &__section-heading {
	//     @extend .section__heading;
	//   }

	//   &__wrap {
	//     @extend .section__wrap;

	//     align-items: stretch;

	//     @include media-breakpoint-up(md) {
	//       flex-direction: row;
	//     }

	//     &--bg-primary {
	//       background-color: $background-primary;
	//     }

	//     &--bg-secondary {
	//       background-color: $background-secondary;
	//     }

	//     &--bg-tertiary {
	//       background-color: $background-tertiary;
	//     }
	//   }

	//   &__col {
	//     width: 100%;
	//     //min-height: 50vh;

	//     @include media-breakpoint-up(md) {
	//       min-height: 0;
	//       max-height: 100vh;
	//       width: 50%;
	//       display: flex;
	//       align-items: center;
	//     }

	//     @include media-breakpoint-up(lg) {
	//       height: 720px;
	//     }

	//     &--image {
	//       background-repeat: no-repeat;
	//       background-size: cover;
	//       background-position: center;
	//       padding-bottom: 70%;
	//       order: -1;

	//       @include media-breakpoint-up(md) {
	//         padding-bottom: 0;
	//         order: unset;
	//       }
	//     }

	//     &--larger {

	//       @include media-breakpoint-up(md) {
	//         width: 65%;
	//       }

	//       @include media-breakpoint-up(lg) {
	//         width: 77%;
	//       }
	//     }
	//   }

	//   &__content {
	//     padding: 2rem 4rem;
	//     margin-left: auto;
	//     margin-right: auto;
	//     text-align: center;
	//     padding-bottom: $padding-large;
	//     padding-top: $padding-large;
	//     width: 100%;

	//     @include media-breakpoint-up(sm) {
	//       padding: 8.6rem;
	//       max-width: 720px;
	//     }

	//     @include media-breakpoint-up(md) {
	//       text-align: left !important;
	//       margin-left: 0;
	//     }

	//     @at-root &__wrap--bg-primary & {
	//       padding-left: em(30);
	//       padding-right: em(30);
	//     }
	//   }

	//   &__content-heading {
	//     @extend .heading-primary-lg;

	//     margin-top: 0;
	//     margin-bottom: 32px;
	//     color: $black;

	//     @at-root .newsletter__wrap--bg-tertiary & {
	//       color: $white;
	//     }
	//   }

	//   &__content-text {
	//     @extend .body-text;

	//     @at-root .newsletter__wrap--bg-tertiary & {
	//       color: $white;
	//     }

	//   }

	//   &__subscribe-input-wrapper {
	//     @extend .input-with-button;
	//     @extend .input-with-button--light;

	//     max-width: none;
	//   }

	//   &__subscribe-input {
	//     @extend .input-with-button__text-field;

	//     @at-root .newsletter__wrap--bg-primary & {
	//       border: solid 1px $grey; 
	//     }
	//   }

	//   &__subscribe-button {
	//     @extend .input-with-button__button;

	//     @at-root .newsletter__wrap--bg-primary & {
	//       color: $grey; 
	//     }
	//   }
}